import type { Procedure } from 'o365-modules';
import type { DataObject, ItemModel } from 'o365-dataobject';
import type { DataGridControl } from 'o365-datagrid';
import { getOrCreateProcedure, userSession } from 'o365-modules';
import { getOrCreateDataObject } from 'o365-dataobject'
import { FilterItem } from 'o365-filterobject'
import { reactive } from 'vue';
import { UpdateLkpSelection, PinnedClick } from './shared.ts'

interface IGridLookupOptions {
    // id?: string,
    columnsList: Array<any>,
    dataObject: DataObject,
    whereClause?: string,
    disablePinned?: boolean,
    bind: Function,
    bindClear: Function,
    suppressClickEvent: Boolean,
    openonfocus: Boolean,
    dropdown: any,
    autosave: Boolean,
    noSearch?: Boolean,
}

const sharedLookupStates: Record<string, {
    initWhereClause?: string;
    // searchValue?: string;
}> = {};

export default class GridLookup {
    private _dataObject?: DataObject;
    /** Lookup Id */
    // private id: string;
    columnsList: Array<string> = [];
    private _whereClause?: string | null = null;
    private _initWhereClause?: string | null = null;
    private _searchString?: string | null = null;
    private _bind: Function;
    private _bindClear: Function;
    private _focusInputAfterClose = false;
    private _dataLoadedListener: Function | null = null;
    private _selectionsDataObject?: DataObject<LookupSelectionsItemModel>;
    useLeftSizers: Boolean = false;



    dataGridRef: DataGridControl;

    disablePinned: Boolean = false;

    navigation: LookupNavigation;

    contextFilterEnabled: Boolean = false;

    private dropdown: any;

    private _props: any;

    private _noSearch?: boolean;

    procRecent: Procedure;
    noHeader: boolean = false;
    /** Text value shown in search input */
    searchTextValue?: string;
    // get searchTextValue() { return sharedLookupStates[this.id].searchValue; }
    // set searchTextValue(newValue: string | undefined) { sharedLookupStates[this.id].searchValue = newValue; }

    get height() {
        return this._props["height"];
    }

    get searchString() {
        return this._searchString;
    }

    get dataObject() {
        return this._dataObject;
    }
    get data() {
        return this._props['data'];
    }

    get columns() {
        return this._props["columns"];
    }

    get placement() {
        return this._props["placement"];
    }

    get filterRow() {
        return this._props["filterRow"];
    }
    get noSearch() {
        return this._noSearch ?? this._props["noSearch"];
    }
    set noSearch(value) {
        this._noSearch = value;
    }
    get noClear() {
        return this._props["noClear"];
    }
    get maxWidth() {
        return this._props["maxWidth"];
    }
    get multiselect() {
        return this._props["multiselect"];
    }
    get autosave() {
        return this._props["autosave"];
    }
    get suppressKeyboardEvents() {
        return this._props['suppressKeyboardEvents'];
    }
    get useSearchColumn(): boolean | undefined {
        return this._props['useSearchColumn'];
    }
    get textInput(): boolean {
        return this._props['textInput'] ?? false;
    }
    get contextField(): string | (() => string) | ((contextIdPath: string) => string) | ((contextIdPath: string, contextId: number) => string) {
        return this._props["contextField"];
    }
    get contextToggleLabel() {
        return this._props["contextToggleLabel"];
    }
    get forceReloadOnOpen() {
        return this._props["forceReloadOnOpen"];
    }

    get whereClause() {
        return this._whereClause;
    }

    get whereClauseProp() {
        return this._props['whereClause'];
    }

    set whereClause(pString) {
        if (pString !== this._whereClause) {
            this._whereClause = pString;
            //  this.load();
        }
    }

    get autoFilterDebounce() {
        return this._props['autoFilterDebounce'];
    }
    get disableAutoFilter() {
        return this._props['disableAutoFilter'];
    }
    get disableDynamicLoading() {
        return this._props['disableDynamicLoading'];
    }
    get nodeData() {
        return this._props["nodeData"];
    }
    get focusField() {
        return this._props['focusField'];
    }
    get reloadOnWhereClauseChange() {
        return this._props['reloadOnWhereClauseChange'];
    }
    get persistentFilterId() {
        return this._props['persistentFilterId'];
    }
    get focusSelector() {
        return this._props['focusSelector'];
    }
    get expandView() {
        return this.dataObject?.recordSource.expandView;
    }
    get rowClass() {
        return this._props['rowClass'];
    }
    get focusInputAfterClose() {
        return this._focusInputAfterClose && !this._props['skipFocusAfterClose'];
    }
    set focusInputAfterClose(pValue) {
        this._focusInputAfterClose = pValue;
    }

    get gridOptions() {
        return this._props['gridOptions'] ?? {};
    }

    get selectionsDataObject() {
        if (this._selectionsDataObject == null) {
            this._selectionsDataObject = this._getSelectionsDataObject();
        }
        return this._selectionsDataObject;
    }

    /** Lookup mode where only recents and pinned are loaded on open, to see other data user action is required (reload or filter) */
    get showRecentsAndPinnedOnly() {
        return this._props.loadRecentsOnlyOnOpen && !this.multiselect && this._dataObject?.loadRecents;
    }
    get readonly() {
        return this._props.readonly;
    }


    constructor(props: IGridLookupOptions) {
        this._dataObject = props.dataObject;
        if (this._dataObject) {
            this._dataObject.filterObject.disableAutoComplete = true;
        }
        //commented to check if there is a need of id prop
        // if (props.id) {
        //     this.id = props.id;
        // } else
        if (props.dataObject) {
            this.id = `${props.dataObject.appId}_${props.dataObject.id}_lookup`;
        } else {
            this.id = `dynamicLookup_${new Date().getTime() * Math.random()}`;
        }

        this._bind = props.bind;
        this._bindClear = props.bindClear;

        this._props = props;

        if (!props.noSearch) {
            this._noSearch = false;
        }

        if (props['disablePinned']) {
            this.disablePinned = props['disablePinned'];
        }

        if (this._shouldDisableRecents()) {
            this.disablePinned = true;
            if(this._dataObject) {
                this._dataObject.loadRecents = false;
            }
        } else if (!this._props.disableRecent && this._dataObject) {
            this._dataObject.loadRecents = true;
        }

        if (!sharedLookupStates[this.id]) {
            sharedLookupStates[this.id] = {
                initWhereClause: this._dataObject?.recordSource.whereClause
            };
        }
        const shared = sharedLookupStates[this.id];

        this._initWhereClause = shared.initWhereClause;

        this._whereClause = props.whereClause;
        
        this.procRecent = getOrCreateProcedure({
            id: 'o_procAddBookmark',
            procedureName: 'sstp_System_LookupSelections'
        });

        this.navigation = reactive(new LookupNavigation(this));
        
        this.contextFilterEnabled = !!this.contextField || !!this.dataObject?.recordSource.expandView;
        if (this.contextFilterEnabled) {
            this.enableContextFilter();
        }

        if (this.multiselect && this._dataObject) {
            if(this._props.itemLoaded) this._dataObject.recordSource.maxRecords = -1;
            this._dataObject.loadRecents = false;
        } 

        if (this._props.itemLoaded && this._dataObject) {
            this._dataLoadedListener = this._dataObject.on('DataLoaded', (data) => {
                data.forEach((item) => {
                    this._props.itemLoaded(item);
                });
            })
        }
    }

    search_old = (pSearchString: string) => {
        let vLeftSide = '';

        this.columnsList.map(col => (col['type'] === 'string' ? `ISNULL(${col['name']},'')` : (`ISNULL(ToStr(${col['name']}),'')`))).forEach((item, index) => {
            vLeftSide += index ? " + " + item : item;
        });

        this._searchString = this._prepareWhereClause(vLeftSide, pSearchString);

        this.navigation.clearState();
        this.load();
    }

    load = async (pSkipFilterClear = false) => {
        if (!this._dataObject) return;
        this._whereClause = this.whereClauseProp;

        // When props.reloadOnWhereClauseChange is used together with props.whereClause the record source where clause must not be used.
        // The props.whereClause in such cases is the sole active whereClause that will realoed the datasource on any change (in open lookups or before open)
        if(!this.reloadOnWhereClauseChange && !this._whereClause){
            this._whereClause = this._dataObject.recordSource.whereClause;
        }

        if (this.showRecentsAndPinnedOnly) {
            await this._loadRecentsAndPinnedOnly();
            return;
        }

        // if(!this.dataGridRef) return;
        const vWhere = [];

        // if (this._searchString) {
        //     vWhere.push(this._searchString);
        // }
        if (this.searchTextValue) {
            this.searchTextValue = undefined;
            if (this._dataObject.filterObject.filterItems['SearchObject']) {
                delete this._dataObject.filterObject.filterItems['SearchObject']
            }
        }

        if (this.forceReloadOnOpen) {
            if (this.whereClause) {
                vWhere.push(this.whereClause);
            }
            if (this._dataObject.recordSource.whereClause && this.whereClause == null) {
                vWhere.push(this._dataObject.recordSource.whereClause);
            }
        } else {
            if (this._whereClause ) {
                vWhere.push(this._whereClause);
            }
            if (this._initWhereClause && this._whereClause != this._initWhereClause) {
                vWhere.push(this._initWhereClause);
            }
        }
        if (vWhere.length > 0) {
            this._dataObject.recordSource.whereClause = vWhere.join(' AND ');
        } else if (!this.forceReloadOnOpen && this._dataObject.recordSource.whereClause) {
            this._dataObject.recordSource.whereClause = '';
        }

        if (!pSkipFilterClear && this._dataObject.filterObject.appliedFilterString && !this._dataObject.filterObject.persistentFilterEnabled) {
            await this._dataObject.filterObject.clear();
        } else if (this.forceReloadOnOpen) {
            await this._dataObject.load();
        } else if (!this._dataObject.state.isLoading) {
            await this._dataObject.recordSource.loadIfWhereClauseChanged();
        }
        if (this._props.itemLoaded) {
            var vLength = this._dataObject.data.length;
            var vStartTimer = Math.max(50,vLength);
            for(let i =0;i<=50;i++){
                this._props.itemLoaded(this._dataObject.data[i]);
            }

            window.setTimeout(()=>{
                
                for(let i =vStartTimer;i<=vLength;i++){
                    this._props.itemLoaded(this._dataObject.data[i]);
                }
            })
            
        }
    }


    private _prepareWhereClause(pLeftSide: string, pValue: any) {
        let vFilter = "";
        if (pValue.startsWith('"') > -1 && pValue.endsWith('"')) {
            vFilter = pLeftSide + ` LIKE '%${pValue}%'`
        } else {
            pValue.split(" ").forEach((item: any, index: any) => {
                vFilter += index ? " AND " + pLeftSide + ` LIKE '%${item}%'` : pLeftSide + ` LIKE '%${item}%'`;
            });
        }
        return vFilter;
    }

    private _shouldDisableRecents() {
        if (this._dataObject == null || this._dataObject.fields['ID'] == null) {
            // Should disable for lookups with no data objects or if ID field is not in view definition 
            return true;
        } else {
            const idFieldSelected = this._dataObject.fields.fieldExists('ID');
            if (idFieldSelected) { 
                // ID field already is in selected fields so loadRecents should not be disabled
                return false; 
            }
            const hasGroupBy = this._dataObject.fields.fields.some(field => field.groupByOrder != null);
            const distinctRows = this._dataObject.recordSource.distinctRows;
            if (hasGroupBy || distinctRows) {
                // ID field is not selected and adding it could break group/distinct selection, should disable recetns
                return true;
            }
            // ID field exists in view but is not selected, enable recents and add it to avoid 'Failed to enable constraints' error
            this._dataObject.fields.addFieldIfExists({ name: 'ID' });
            return false;
        }
    }

    handleRowClick = (row: any) => {
        if (row.Disabled) { return; }
        if (this.multiselect) {
            if (row) {
                row.isSelected = !row.isSelected;
                if (this._props.bindOnSelect) {
                    this.handleMultiselect(false)
                }
            }
            return;
        };
        if (this._bind) this._bind(row);
        if (this.dataObject) {
            UpdateLkpSelection(this.procRecent, this._dataObject, row);
            if (this.dataObject.loadRecents) {
                row._recent = true;
            }
            if (this.autosave) {
                this.dataObject.save();
            }
            this.dataObject.setCurrentIndex(row.index);
        }
        this.close(true);
    }

    handleMultiselect = async (pClose = true) => {
        if (this._bind) {
            if (this._dataObject) {
                if (this._dataObject.selectionControl.selectionSave) {
                    let selectedRows = Array.from(this._dataObject.selectionControl.selectedDataItems);
                    if (selectedRows.length == 0) {
                        selectedRows = await this._getSelectedRows();
                    }
                    //selectedRows = Array.from(selectedRows).concat(this._dataObject.data.filter((x: { [x: string]: any; }) => x && x['isSelected']));
                    this._bind(selectedRows);
                } else {
                    this._bind(this._dataObject.data.filter((x: { [x: string]: any; }) => x && x['isSelected']));
                }
            } else {
                this._bind(this._props?.data.filter(x => x.isSelected));
            }
        }
        if (pClose) {
            this.close(true);
        }
    }

    private async _getSelectedRows() {
        if (!this._dataObject!.selectionControl.selectedUniqueKeys.size) { return Promise.resolve([]); }
        let options = { ...this._dataObject!.recordSource.getOptions() };
        options.filterString = `[${this._dataObject!.fields.uniqueField}] IN ('${Array.from(this._dataObject!.selectionControl.selectedUniqueKeys).join("','")}')`;

        return await this._dataObject!.dataHandler.retrieve(options);
    }

    handleClearSelection = () => {
        if (this._bindClear) {
            this._bindClear();
        } else if (this._bind) {
            if (this.multiselect) {
                this._dataObject?.data.forEach(row => row.isSelected = false);
                this._bind([]);
            } else {
                const nullObject = new Proxy({}, {
                    get: function (_target, _prop) {
                        return null;
                    }
                });
                this._bind(nullObject);
            }
            // const emptyRow = {};
            // this.columnsList.forEach(col => {
            //     emptyRow[col['name']] = null;
            // });
            // this._bind(emptyRow);
        }
        this.close(true);
    }

    pinnedClick = (item: any, ev: { preventDefault: () => void; stopPropagation: () => void; stopImmediatePropagation: () => void; }) => {
        PinnedClick(this.procRecent, this._dataObject, item);
        ev.preventDefault();
        ev.stopPropagation();
        ev.stopImmediatePropagation();

    }

    close(pFocusInput = false) {
        this.focusInputAfterClose = pFocusInput
        if (this._dataLoadedListener !== null) {
            this._dataLoadedListener();
            this._dataLoadedListener = null;
        }
        if (this.dropdown) {
            this.dropdown.close();
        }
    }

    open() {
        this.focusInputAfterClose = false;
        if (this.dropdown) {
            this.dropdown.open();
        }
    }

    //--- Search logic ---
    search = (pSearchString: string, pIsUserInput = false) => {
        if (pIsUserInput) {
            if (this.navigation.lastKeyIsNavigation) {
                return;
            } else {
                this.navigation.clearState();
            }
        }
        const _filterObject = this.dataObject?.filterObject ?? this.dataGridRef?.dataGridControl?.filterObject;
        if (this.useSearchColumn) {
            if (!pSearchString) {
                _filterObject.getItem('SearchColumn').selectedValue = null;
            } else {
                _filterObject.getItem('SearchColumn').operator = 'contains';
                _filterObject.getItem('SearchColumn').selectedValue = pSearchString;
            }
            _filterObject.apply();
        } else {
            const allColumns = this.dataGridRef.dataColumns.columns.filter(col => !col.colId.startsWith('o365'));
            const filterObject = new FilterItem({});
            filterObject.type = 'group';
            filterObject.mode = 'and';
            filterObject.items = [];
            const searchTerms = pSearchString.split(/\s+/).filter(v => v);

            for (let term of searchTerms) {
                const innerGroup = new FilterItem({});
                innerGroup.type = 'group';
                innerGroup.mode = 'or';
                innerGroup.items = [];

                for (let column of allColumns) {
                    const expression = new FilterItem({ column: column.field });
                    expression.operator = 'contains';
                    expression.selectedValue = term;

                    innerGroup.items.push(expression);
                }
                filterObject.items.push(innerGroup);
            }

            _filterObject.filterItems['SearchObject'] = filterObject;
            _filterObject.updateFilterString();
            _filterObject.apply();
        }
    }

    enableContextFilter() {
        if (this.expandView) {
            this.contextFilterEnabled = true;
            this.dataObject!.recordSource.contextId = undefined;
            return;
        }

        if (!this.contextField) {
            console.error("Tried to set context filter without passing contextField prop");
            return;
        }
        if (typeof this.contextField === "function") {
            this.dataObject?.enableContextFilter(this.contextField, { autoLoad: false });
        } else {
            this.dataObject?.enableContextFilter({ idPathField: this.contextField }, { autoLoad: false });
        }        
        this.contextFilterEnabled = true;
    }

    disableContextFilter() {
         if (this.expandView) {
            this.contextFilterEnabled = false;
            this.dataObject!.recordSource.contextId = null;
            return;
        }

        this.dataObject?.enableContextFilter(null);
        this.dataObject!.recordSource.contextId = null;
        this.contextFilterEnabled = false;
    }

    /** Get recent/pinned selection dataobject. It is shared betweeen all lookups */
    private _getSelectionsDataObject() {
        return getOrCreateDataObject({
            id: 'dsLookupSelections',
            viewName: 'stbv_System_LookupSelections',
            fields: [{ name: 'Record_ID' }, { name: 'Pinned' }, { name: 'Updated', sortOrder: 1, sortDirection: 'desc' }],
            maxRecords: -1,
        });
    }

    /** Get recent and pinned items */
    private async _loadRecentsAndPinnedOnly() {
        if (this._dataObject == null) { return; }
        this._dataObject.state.isLoading = true;
        const returnTasks = (pLength = 0) => {
            if (this._dataObject == null) { return; }
            this._dataObject.state.isLoading = false;
            this._dataObject.state.isLoaded = true;
            this._dataObject.state.rowCount = pLength;
            this._dataObject.state.isRowCountLoaded = true;
            if (pLength) {
                this._dataObject.setCurrentIndex(0);
            } else {
                this._dataObject.unsetCurrentIndex();
            }
            if (this._dataObject.hasDynamicLoading) {
                this._dataObject.dynamicLoading.setItems(this._dataObject.storage.data, 0);
                this._dataObject.emit('DynamicDataLoaded', false, this._dataObject.storage.data);
                if (this._dataObject.dynamicLoading.onDataLoaded) {
                    this._dataObject.dynamicLoading.onDataLoaded(true);
                }
            }
        }
        this._dataObject.storage.clearItems();

        const selectedRecords = await this.selectionsDataObject.recordSource.retrieve({
            whereClause: `[ViewName] = '${this._dataObject.viewName}'`
        });

        if (selectedRecords.length == 0) { returnTasks(); return; }

        const selectedItemsFilter = `[ID] IN (${selectedRecords.map(item => `'${item.Record_ID}'`).join(',')})`;
        const options = this._dataObject.recordSource.getOptions();
        options.whereClause - this.reloadOnWhereClauseChange ? this._whereClause : options.whereClause;
        this._dataObject.emit('BeforeLoad', options);
        this._dataObject.filterObject.clear(true);
        const data = await this._dataObject.recordSource.retrieve({
            ...options,
            loadRecents: false,
            whereClause: options.whereClause ? `(${options.whereClause}) AND ${selectedItemsFilter}` : selectedItemsFilter
        });
        if (!data?.length) { returnTasks(); return; }
        const pinnedRows: ItemModel[] = [];
        const recentRows: ItemModel[] = [];
        data.forEach(item => {
            const selectedItem = selectedRecords.find(x => x.Record_ID == item.ID);
            if (selectedItem?.Pinned) {
                pinnedRows.push({
                    _pinned: true,
                    _recent: null,
                    ...item
                });
            } else {
                recentRows.push({
                    _pinned: null,
                    _recent: true,
                    ...item
                });
            }
        });
        this._dataObject.storage.setItems([...pinnedRows, ...recentRows], true);
        returnTasks(data.length);
    }
}

class LookupNavigation {
    currentIndex: number;
    lastKeyIsNavigation: boolean;
    private _container: HTMLElement;
    private _boundKeyDown: typeof this._handleKeyDown;
    private _getControl: () => GridLookup;

    constructor(lookupCtrl: GridLookup) {
        this._getControl = () => lookupCtrl;

        /*
        import('o365.vue.composables.DataGridNavigation.ts').then(x => {
            this._ariaParser = x.AriaIndexParser;
        });
        */
    }

    clearState() {
        this.currentIndex = null;
        this.lastKeyIsNavigation = false;
    }

    addHandler(container: HTMLElement) {
        this._container = container;
        this._boundKeyDown = this._handleKeyDown.bind(this);
        this.clearState();
        this._container.addEventListener('keydown', this._boundKeyDown);
    }

    removeHandler() {
        this._container.removeEventListener('keydown', this._boundKeyDown);
    }

    private _handleKeyDown(e: KeyboardEvent) {
        switch (e.key) {
            case 'Escape':
                this._getControl().close(true);
                break;
            case 'ArrowDown':
                e.preventDefault();
                this.lastKeyIsNavigation = true;
                this._moveDown();
                break;
            case 'ArrowUp':
                e.preventDefault();
                this.lastKeyIsNavigation = true;
                this._moveUp();
                break;
            case 'Enter':
                this._selectCurrentRow();
                break;
            default:
                this.lastKeyIsNavigation = false;
                break;
        }
    }

    private _moveDown() {
        const moved = this._changeIndex(false);
        if (moved) {
            this._scrollToCurrent();
        }

    }

    private _moveUp() {
        const moved = this._changeIndex(true);
        if (moved) {
            this._scrollToCurrent();
        }
    }

    private _changeIndex(decrement = false) {
        if (this.currentIndex == null) {
            if (this._rowExists(0)) {
                this.currentIndex = 0;
                return true;
            } else {
                return false;
            }
        } else {
            const newIndex = decrement ? this.currentIndex - 1 : this.currentIndex + 1;
            if (this._rowExists(newIndex)) {
                this.currentIndex = newIndex;
                return true;
            } else {
                return false;
            }
        }
    }

    private _rowExists(index: number) {
        const control = this._getControl();
        return !!control.dataObject.data[index];
    }

    private _selectCurrentRow() {
        if (!this.lastKeyIsNavigation || !this._rowExists(this.currentIndex)) { return; }
        const control = this._getControl();
        const row = control.dataObject.data[this.currentIndex];
        control.handleRowClick(row);
    }

    private _scrollToCurrent() {
        const scrollContainer = this._container.querySelector('.o365-body-center-viewport')
        const buffer = scrollContainer.clientHeight / 2;

        const newScroll = this.currentIndex * 34;
        const topScroll = scrollContainer.scrollTop;
        const bottomScroll = topScroll + scrollContainer.clientHeight;

        if (newScroll <= bottomScroll + buffer) {
            scrollContainer.scrollTop = newScroll - buffer;
        } else if (topScroll <= newScroll) {
            scrollContainer.scrollTop = newScroll + buffer;
        }
    }
}

type LookupSelectionsItemModel = {
    Record_ID: number,
    Pinned?: boolean,
    Updated: string|Date
}